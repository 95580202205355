import React from 'react';
import ReactDOM from 'react-dom';
import {
  StylesProvider,
  createGenerateClassName,
} from '@material-ui/core/styles';
import News from './News.jsx';
import './intramuros.css';

const generateClassName = createGenerateClassName({
  seed: 'news',
});

export function initNewsWidget(cityId, aggloId, cityINSEE, aggloSIREN, excludeActors, intramurosTopicId) {
  const rootDiv = document.getElementById('intramuros_news');
  if (rootDiv) {
    // Remove previous instance if exists
    const previousChildNode = document.getElementById(
      'intramuros-news-content',
    );
    if (previousChildNode) {
      rootDiv.removeChild(previousChildNode);
    }

    const node = document.createElement('div');
    node.setAttribute('id', 'intramuros-news-content');

    const child = rootDiv.appendChild(node);

    console.log('Init IntraMuros News widget');
    ReactDOM.render(
      <StylesProvider generateClassName={generateClassName}>
        <News
          cityId={cityId}
          aggloId={aggloId}
          cityINSEE={cityINSEE}
          aggloSIREN={aggloSIREN}
          excludeActors={excludeActors}
          intramurosTopicId={intramurosTopicId}
        />
      </StylesProvider>,
      child,
    );
  } else {
    console.log('No tag with id: intramuros_news');
  }
}

// Launch widget
console.log('Widget Actualité chargé.');
console.log('TARGET_ENV: ' + process.env.TARGET_ENV);
console.log('NODE_ENV: ' + process.env.NODE_ENV);

const {
  intramurosCityId,
  intramurosAggloId,
  intramurosCityINSEE,
  intramurosAggloSIREN,
  excludeActors,
  intramurosTopicId,
} = window;

console.log('City ID = ' + intramurosCityId);
console.log('City INSEE = ' + intramurosCityINSEE);
console.log('Agglo ID = ' + intramurosAggloId);
console.log('Agglo SIREN = ' + intramurosAggloSIREN);

if (
  intramurosCityId ||
  intramurosAggloId ||
  intramurosCityINSEE ||
  intramurosAggloSIREN
) {
  initNewsWidget(
    intramurosCityId,
    intramurosAggloId,
    intramurosCityINSEE,
    intramurosAggloSIREN,
    excludeActors,
    intramurosTopicId,
  );
}
